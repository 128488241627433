<template>
  <div class="login">
    <SimpleNavbarComp />
    <div class="content-block">
      <img src="./../assets/logow.svg" alt="">
      <div class="btn-group">
        <input v-model="email" type="email" class="form-group input" placeholder="E-mail" autofocus required>
        <input v-model="password" type="password" class="form-group input" placeholder="Senha" required>
        <router-link to="" @click="login" class="btn btn-primary">
          <span>
            <b>Entrar</b>
          </span>
        </router-link>
        <router-link to="#" class="btn-slim btn-transparent">
          <span>
            Ainda não tenho acesso!
          </span>
        </router-link>
      </div>
    </div>
    <footer>
      <span>
        Diretu Soluções Imobiliárias.<br>
        Todos os direitos reservados.
        &copy; 2024 - 2024.<br>
        Leia os nossos <router-link to="#"><span>Termos de Uso</span></router-link>. 
      </span>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
import SimpleNavbarComp from "@/components/SimpleNavbarComp.vue";

export default {
  name: 'LoginCorretorView',
  components: { SimpleNavbarComp },
  data() {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    async login() {
      try {
        const data = {
          email: this.email,
          password: this.password
        };

        // Requisição de login
        const API_URL = process.env.NODE_ENV === 'production' ? 'https://api.diretu.com' : '/api';

        const response = await axios.post(`${API_URL}/auth/login`, data, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });


        // Salvar o token no localStorage
        localStorage.setItem('token', response.data.data.access_token);

        // Redirecionar para o feed
        this.$router.push('/corretor/feed');
      } catch (error) {
        console.error('Erro ao fazer login:', error);
        // Exibir mensagem de erro para o usuário
      }
    }
  }
};
</script>

<style scoped lang="less">
@basecolor: #EA2027;

.login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-image: url('./../assets/bg3.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 15px;
  margin: 0 auto;

  @media screen and (min-width: 500px) {
    max-width: 500px;
  }

  .content-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px auto 15px auto;

    img {
      height: 100px;
      transform: translateY(20px);
    }

    h5 {
      color: #ffffff;
      text-align: center;
      font-weight: 500;
      padding: 0px 15px;

      a {
        span {
          color: @basecolor;
        }
      }
    }
  }

  footer {
    width: 100%;
    background-color: #00000033;
    backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 1000px;

    span {
      text-align: center;
      color: #ffffff;
      font-size: .8rem;

      a {
        span {
          color: @basecolor;
        }
      }
    }
  }
}
</style>
