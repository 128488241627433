<template>
    <div class="simple-navbar">
        <nav>
            <router-link to="/">
                <ion-icon name="chevron-back"></ion-icon>
                <span>voltar</span>
            </router-link>
        </nav>
    </div>
</template>
<script></script>
<style scoped lang="less">
.simple-navbar {
    width: 100%;
    max-width: 500px;
    height: 92px;
    position: fixed;
    z-index: 1;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;

    nav {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        a {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            font-size: 14pt;
            padding: 0px 15px;

            span {
                font-size: 12pt;
            }
        }
    }
}
</style>