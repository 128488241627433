// src/services/api.js
import axios from 'axios';

// Define a URL base dependendo do ambiente
const API_URL = process.env.NODE_ENV === 'production' ? 'https://api.diretu.com' : '/api';

const instance = axios.create({
    baseURL: API_URL, // Usa o proxy '/api' em desenvolvimento e a URL real em produção
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

// Interceptador de resposta para tratar o erro 429 (Too Many Requests)
instance.interceptors.response.use(null, async (error) => {
    if (error.response && error.response.status === 429) {
        const retryAfter = error.response.headers['retry-after'];
        await new Promise((resolve) => setTimeout(resolve, (retryAfter || 1) * 1000)); // Aguarda o tempo especificado
        return instance.request(error.config); // Reenvia a requisição original
    }
    return Promise.reject(error); // Retorna o erro se não for 429
});

export default instance; // Exporta a instância configurada
