import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/views/LoginView.vue';
import LoginCorretorView from '@/views/LoginCorretorView.vue';
import CorretorFeedView from '@/views/CorretorFeedView.vue';
import api from '@/services/api'; // Importa o serviço API configurado

// Definição das rotas
const routes = [
  { path: '/', name: 'login', component: Login },
  { path: '/login/corretor', name: 'logincorretor', component: LoginCorretorView },
  { path: '/corretor/feed', name: 'corretorfeed', component: CorretorFeedView, meta: { requiresAuth: true } },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Guardião de rota para verificação de autenticação
router.beforeEach(async (to, from, next) => {
  const authRequired = to.matched.some(record => record.meta.requiresAuth);
  const token = localStorage.getItem('token');

  // Se o usuário está autenticado e tenta acessar uma rota pública, redireciona para /corretor/feed
  if (token && (
    to.path === '/' || 
    to.path === '/login' || 
    to.path === '/login/corretor'
  )) {
    return next('/corretor/feed');
  }

  // Redireciona para o login se a rota requer autenticação e o token não está presente
  if (authRequired && !token) {
    return next('/login/corretor');
  }

  // Verifica e renova o token se estiver presente
  if (token) {
    const tokenValid = await checkAndRenewToken();
    if (!tokenValid) {
      return next('/login/corretor');
    }
  }

  next(); // Permite o acesso à rota
});

// Função para verificar e renovar o token
async function checkAndRenewToken() {
  const token = localStorage.getItem('token');
  if (!token) {
    return false;
  }

  try {
    const response = await api.post('/auth/refresh-token', {}, { // Usa a instância do `api.js`
      headers: { 'Authorization': `Bearer ${token}` },
    });
    localStorage.setItem('token', response.data.data.access_token);
    return true;
  } catch (error) {
    console.error('Erro ao renovar token:', error);
    localStorage.removeItem('token');
    return false;
  }
}

export default router;
