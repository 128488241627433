<template>
  <router-view />
</template>

<style lang="less">
@basecolor: #EA2027;
@maincolor: #8D1A1E;

* {
  margin: 0px;
  padding: 0px;
  outline: none !important;
  text-decoration: none !important;
  box-sizing: border-box !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &:hover {
    outline: none !important;
  }
}

html,
body {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#app {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, @basecolor, @maincolor);
}

.btn-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .btn {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 50px;
    margin: 10px auto 0px auto;
  }

  .input {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 50px;
    margin: 10px auto 0px auto;
    border: 1px solid #ffffff;
    background: transparent;
    backdrop-filter: blur(20px);
    padding: 0px 15px;
    font-size: 14pt;
    color: #ffffff;
  }

  .btn-slim {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 50px;
    margin: 10px auto 0px auto;
  }

  .btn-transparent {
    color: #ffffff;
    text-decoration: underline !important;
  }

  .btn-primary {
    background-color: @basecolor;
    color: #ffffff;
  }

  .btn-light {
    background-color: #ffffff;
    color: #000000;
  }
}
</style>
