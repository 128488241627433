<template>
  <div class="corretor-feed">
    <aside>
      Menu
    </aside>
    <main>
      <CorretorFeedComp />
    </main>
    <section>
      Ads
    </section>
  </div>
</template>

<script>
import CorretorFeedComp from '@/components/CorretorFeedComp.vue';

export default {
  name: "CorretorFeed",
  components: {
    CorretorFeedComp
  }
}

</script>

<style scoped lang="less">
.corretor-feed {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  aside {
    width: calc(100% / 4);
    height: 100%;
    border-right: 1px solid #00000022;

    @media screen and (max-width: 700px) {
      display: none;
    }
  }

  main {
    width: 100%;
    height: 100%;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  section {
    width: calc(100% / 4);
    height: 100%;
    border-left: 1px solid #00000022;

    @media screen and (max-width: 700px) {
      display: none;
    }
  }

}
</style>