<template>
    <div class="corretor-feed-comp">
        <div class="diretu">
            <header>
                <div class="user-photo" :style="{
                    backgroundImage: `url(${require('@/assets/users/jorgeprovenzano_photoperfil.png')})`
                }"></div>

                <div class="user-info">
                    <div class="user-info-name">Jorge Provenzano</div>
                    <div class="user-info-type">Corretor de Imóveis</div>
                </div>
                <div class="user-follow">
                    <button><ion-icon name="person-add-outline"></ion-icon></button>
                </div>
            </header>
            <article>
                <img src="./../assets/users/post01.jpeg" alt="post01">
            </article>
            <footer></footer>
        </div>
        <div class="diretu">
            <header>
                <div class="user-photo" :style="{
                    backgroundImage: `url(${require('@/assets/users/jorgeprovenzano_photoperfil.png')})`
                }"></div>

                <div class="user-info">
                    <div class="user-info-name">Jorge Provenzano</div>
                    <div class="user-info-type">Corretor de Imóveis</div>
                </div>
                <div class="user-follow">
                    <button><ion-icon name="person-add-outline"></ion-icon></button>
                </div>
            </header>
            <article>
                <img src="./../assets/users/post01.png" alt="post01">
                <img src="./../assets/users/post02.webp" alt="post02">
            </article>
            <footer></footer>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CorretorFeedComp',
    data() {
        return {}
    }
}
</script>

<style lang="less" scoped>
.corretor-feed-comp {
    width: 100%;
    max-width: 500px;
    height: 100vh;
    scroll-snap-type: y mandatory;
    scroll-behavior: auto;
    overflow-y: scroll;

    .diretu {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #ffffff;
        background-color: #222222;
        scroll-snap-align: center;
        position: relative;
        overflow: hidden;

        header {
            width: 100%;
            position: absolute;
            top: 53px;
            z-index: 1;
            display: flex;
            align-items: center;
            gap: 15px;
            padding: 0px 15px;
            margin-top: 10px;

            .user-photo {
                width: 50px;
                aspect-ratio: 1 / 1;
                background-color: #333333;
                border-radius: 50px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
            }

            .user-info {
                flex-grow: 1;

                .user-info-name {
                    font-weight: bold;
                    font-size: 14pt;
                }
            }

            .user-follow {
                width: 50px;
                aspect-ratio: 1 / 1;
                display: flex;
                justify-content: center;
                align-items: center;

                button {
                    background: transparent;
                    border: none;
                    font-size: 18pt;
                    color: #ffffff;
                }
            }
        }

        article {
            display: flex;
            width: 100%;
            height: 100vh;
            scroll-snap-type: x mandatory;
            overflow-x: auto;
            overflow-y: hidden;
            position: relative;

            img {
                min-width: 100%;
                height: 100%;
                object-fit: cover;
                scroll-snap-align: center;
            }
        }

        footer {
            width: 100%;
        }
    }
}
</style>
