<template>
  <div class="login">
    <div class="content-block">
      <img src="./../assets/logow.svg" alt="">
      <div class="btn-group">
        <router-link to="/login/corretor" class="btn btn-primary">
          <span>
            Entrar como <b>Corretor</b>
          </span>
        </router-link>
        <router-link to="#" class="btn btn-light">
          <span>
            Entrar como <b>Imobiliária</b>
          </span>
        </router-link>
        <router-link to="#" class="btn btn-light">
          <span>
            Entrar como <b>Construtora</b>
          </span>
        </router-link>
        <router-link to="#" class="btn-slim btn-transparent">
          <span>
            Ainda não tenho acesso!
          </span>
        </router-link>
      </div>
    </div>
    <footer>
      <span>
        Diretu Soluções Imobiliárias.<br>
        Todos os direitos reservados. 
        &copy; 2024 - 2024.<br>
        Leia os nossos <router-link to="#"><span>Termos de Uso</span></router-link>.
      </span>

    </footer>
  </div>
</template>

<script>

export default {
  name: 'LoginView',
  components: {
  }
}
</script>

<style scoped lang="less">
@basecolor: #EA2027;
@maincolor: #8D1A1E;

.login {
  width: 100%;
  max-width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-image: url('./../assets/bg2.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 15px;
  margin: 0 auto;

  .content-block {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px auto 15px auto;

    img {
      height: 100px;
      transform: translateY(20px);
    }

    h5 {
      color: #ffffff;
      text-align: center;
      font-weight: 500;
      padding: 0px 15px;

      a {
        span {
          color: @basecolor;
        }
      }
    }
  }

  footer {
    width: 100%;
    max-width: 500px;
    background-color: #00000033;
    backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 1000px;

    span {
      text-align: center;
      color: #ffffff;
      font-size: .8rem;

      a {
        span {
          color: @basecolor;
        }
      }
    }
  }
}
</style>
